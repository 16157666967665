import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { useDrawer } from "../../context/drawer";
import AddProperties from "../Forms/Properties/AddProperties";
import UpdateProperty from "../Forms/Properties/UpdateProperty";

const DrawerComp = () => {
  const { drawerData, toggleDrawer } = useDrawer();
  const [listData, setListData] = useState(null);

  useEffect(() => {
    if (drawerData.value !== null) {
      setListData(drawerData.value);
    }
  }, [drawerData]);

  return (
    <>
      <Drawer
        title={drawerData.title}
        placement="right"
        width={500}
        onClose={toggleDrawer}
        open={drawerData.isOpen}
      >
        {drawerData.type === "propertyAdd" ? <AddProperties /> : null}
        {drawerData.type === "propertyUpdate" ? <UpdateProperty /> : null}
      </Drawer>
    </>
  );
};
export default DrawerComp;
