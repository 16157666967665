import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import Properties from "./pages/Properties";
import Enquiries from "./pages/Enquiries";
import LayoutContext from "./context/layout-context";
import Login from "./pages/login";
import NotFound from "./pages/404";

function App() {
  const { token } = useSelector((state) => state.auth);

  return (
    <>
      <BrowserRouter>
        {/* Routes with header and sidebar */}
        {token ? (
          <LayoutContext>
            <Routes>
              <Route path="/" element={<Navigate to="/properties" />} />
              <Route path="/properties" element={<Properties />} />
              <Route path="/enquires" element={<Enquiries />} />
            </Routes>
          </LayoutContext>
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
