import { Form, Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { PiCurrencyInrBold } from "react-icons/pi";

const residentialSpace = [
  "Any",
  "1 RK",
  "1 BHK",
  "2 BHK",
  "3 BHK",
  "4 BHK",
  "5 BHK",
  "7 BHK",
  "8 BHK",
  "9 BHK",
  "10 BHK",
  "Plots",
  "Bunglows",
  "Preleased",
];
const comercialSpace = [
  "Shop",
  "Office",
  "Showrooms",
  "Preleased",
  "Resturant",
  "Coworking Space",
];

export default function DetailForm({
  setFormData,
  formData,
  setSearchData,
  locations,
}) {
  const [selectedCategory, setSelectedCategory] = useState("residential");
  const [locationType, setLocationType] = useState("Old");

  return (
    <div>
      <Form layout="vertical" autoComplete="off">
        <div className="row">
          <div className="col-md-12">
            <Form.Item
              label="Name"
              name="property_name"
              rules={[{ required: true }]}
            >
              <Input
                className="formInput"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_name: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Category"
              name="property_categ"
              rules={[{ required: true }]}
            >
              <Select
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                placeholder="Select Category"
                value={selectedCategory}
                onChange={(value) => {
                  setSelectedCategory(value);
                  setFormData((prev) => ({
                    ...prev,
                    property_categ: value,
                  }));
                }}
                options={[
                  {
                    label: "Residential",
                    value: "residential",
                  },
                  {
                    label: "Commercial",
                    value: "commercial",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Type"
              name="property_type"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select Category"
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                value={formData.property_type}
                onChange={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_type: value,
                  }))
                }
                options={[
                  {
                    label: "Outright",
                    value: "outright",
                  },
                  {
                    label: "Lease",
                    value: "lease",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Space"
              name="property_space"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select Space"
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                value={formData.property_space}
                onChange={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_space: value,
                  }))
                }
              >
                {(selectedCategory === "residential"
                  ? residentialSpace
                  : comercialSpace
                )?.map((valueData) => (
                  <Select.Option value={valueData} label={valueData}>
                    {valueData}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-3">
            <Form.Item label="Price" name="price" rules={[{ required: true }]}>
              <Input
                style={{ width: "100%" }}
                className="formInput"
                prefix={<PiCurrencyInrBold />}
                min={0}
                value={formData.price}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    price: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-3">
            <Form.Item label=" " name="type">
              <Select
                placeholder="Amt Type"
                onChange={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    priceType: value,
                  }))
                }
                options={[
                  { label: "K", value: "K" },
                  { label: "Lac", value: "Lac" },
                  { label: "Cr", value: "Cr" },
                ]}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Usable Carpet Area"
              name="usable_carpet"
              rules={[{ required: true }]}
            >
              <Input
                className="formInput"
                value={formData.usable_carpet}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    usable_carpet: e.target.value,
                  }))
                }
                suffix="Sq.Ft"
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Rera Carpet Area"
              name="rera_carpet"
              rules={[{ required: true }]}
            >
              <Input
                className="formInput"
                value={formData.rera_carpet}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    rera_carpet: e.target.value,
                  }))
                }
                suffix="Sq.Ft"
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Possession"
              name="possession"
              rules={[{ required: true }]}
            >
              <Input
                className="formInput"
                value={formData.possession}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    possession: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Age of Property"
              name="age_of_property"
              rules={[{ required: true }]}
            >
              <Input
                className="formInput"
                value={formData.age_of_property}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    age_of_property: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Condition"
              name="property_condition"
              rules={[{ required: true }]}
            >
              <Input
                className="formInput"
                value={formData.property_condition}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_condition: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="configuration"
              name="configuration"
              rules={[{ required: true }]}
            >
              <Input
                className="formInput"
                value={formData.configuration}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    configuration: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Floor" name="floor" rules={[{ required: true }]}>
              <Input
                className="formInput"
                value={formData.floor}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    floor: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Bathroom"
              name="bathroom"
              rules={[{ required: true }]}
            >
              <Input
                className="formInput"
                value={formData.bathroom}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    bathroom: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <Form.Item
            label="Parking"
            name="parking"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Parking"
              style={{ minHeight: "35px", borderColor: "#afafaf" }}
              value={formData.parking}
              onChange={(value) =>
                setFormData((prev) => ({
                  ...prev,
                  parking: value,
                }))
              }
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
          </Form.Item>
          <div className="col-md-4">
            <Form.Item
              label="Location Type"
              name="location_type"
              rules={[{ required: true }]}
            >
              <Select
                value={locationType}
                onChange={(value) => {
                  setLocationType(value);
                  setFormData((prev) => ({
                    ...prev,
                    location_type: value,
                  }));
                }}
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                onClick={() => setSearchData({ type: "", search: "" })}
                onSearch={(value) => setSearchData({ type: "", search: value })}
                placeholder="Type"
                options={[
                  { label: "Previous Location", value: "Old" },
                  { label: "Add New Location", value: "New" },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-md-8">
            <Form.Item
              label="Select Location"
              name="locality"
              rules={[{ required: true }]}
            >
              {locationType === "Old" ? (
                <Select
                  placeholder="Select Location"
                  style={{ minHeight: "35px", borderColor: "#afafaf" }}
                  value={formData.locality}
                  onChange={(value) =>
                    setFormData((prev) => ({ ...prev, locality: value }))
                  }
                >
                  {locations?.map((value) => (
                    <Select.Option
                      value={value.locality}
                      label={value.locality}
                    >
                      {value.locality}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input
                  className="formInput"
                  value={formData.locality}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, locality: e.target.value }))
                  }
                />
              )}
            </Form.Item>
          </div>

          <Form.Item
            label="Address"
            name="property_address"
            rules={[{ required: true }]}
          >
            <TextArea
              showCount
              maxLength={500}
              value={formData.property_address}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  property_address: e.target.value,
                }))
              }
            />
          </Form.Item>

          <Form.Item
            label="Description"
            name="property_desc"
            rules={[{ required: true }]}
          >
            <TextArea
              showCount
              maxLength={500}
              value={formData.property_desc}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  property_desc: e.target.value,
                }))
              }
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
