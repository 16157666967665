import React from "react";
import { Button, Layout, Menu } from "antd";
import { useSideBar } from "../../context/nav-toggle";
import {
  BiListUl,
  BiLogOut,
  BiSolidDashboard,
  BiSolidOffer,
} from "react-icons/bi";
import { FaUsersCog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MenuItem from "antd/es/menu/MenuItem";
import { BsPersonWorkspace } from "react-icons/bs";
import { MdAccountCircle, MdOutlinePayment } from "react-icons/md";
import { getProfile, removeToken } from "../../services/localStorageServices";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { IoQrCodeSharp } from "react-icons/io5";

const { Sider } = Layout;


export default function Sidebar() {
  const { isOpen } = useSideBar();
  const { profile } = getProfile();
  const navigate = useNavigate();

  const navigateToPage = (url) => {
    navigate(url);
  };

  const logout = () => {
    removeToken();
    navigate("/login");
  };
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        left: 0,
        top: 0,
        bottom: 0,
      }}
      trigger={null}
      collapsible
      collapsed={isOpen}
    >
      <div className="demo-logo-vertical">SKDM Admin</div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
        <MenuItem
          key="1"
          icon={<BiSolidDashboard />}
          onClick={() => navigateToPage("/properties")}
        >
          Properties
        </MenuItem>
        <MenuItem
          key="3"
          icon={<BsPersonWorkspace />}
          onClick={() => navigateToPage("/enquires")}
        >
          Enquiries
        </MenuItem>
        <MenuItem
          key="8"
          // icon={<BiListUl />}
          // onClick={() => navigateToPage("/bookings")}
        >
          <Button onClick={logout} className="logoutBtn">
            <BiLogOut />
            &nbsp;Log Out
          </Button>
        </MenuItem>
      </Menu>
    </Sider>
  );
}
