import { Button, Empty, message, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDrawer } from "../../../../context/drawer";
import { post } from "../../../../services/userAuthAPI";

export default function ImagesForm() {
  const { drawerData, toggleDrawer } = useDrawer();

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const imageData = drawerData?.value?.images?.map((valueData) => ({
      image_id: valueData.image_id,
      thumbUrl: valueData.image_link,
      name: valueData.image_link,
    }));

    setFileList(imageData);
  }, [drawerData]);

  const removeImage = async (file) => {
    if (window.confirm("Are you sure you want to remove image ? ")) {
      try {
        setLoading(true);
        const formData = new FormData();

        formData.append("image_id", file.image_id);
        formData.append("action", "removeImage");

        const resp = await post("/property.php", formData);

        if (resp) {
          setLoading(false);
          message.success("Uploaded success");
          toggleDrawer()
        }
      } catch (error) {
        setLoading(false);
        message.error("Unable to remove please try again !");
        console.error(error);
      }
      return true;
    } else {
      return false;
    }
  };

  const handleUploadImage = async ({ file, fileList }) => {
    try {
      setLoading(true);
      const formData = new FormData();
  
      console.log(file)
      formData.append("action", "addImages");
      formData.append("property_id", drawerData?.value?.property_id);
      formData.append("images", file.originFileObj);
  
      const resp = await post("/property.php", formData);
  
      if (resp) {
        message.success("Image uploaded successfully");
        file.image_id = resp.id;
  
        setFileList((prev) => [...prev, { ...file, image_id: resp.id }]);
      } else {
        throw new Error("Image upload failed. Please try again.");
      }
    } catch (error) {
      message.error(error.message || "An error occurred during image upload.");
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="mb-4">
      <Spin spinning={loading}>
        <Upload
          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
          listType="picture"
          fileList={[...fileList]}
          onChange={handleUploadImage}
          accept="image/*"
          onRemove={removeImage}
        >
          <Button
            style={{ width: "100%", border: "1px solid #000", height: "40px" }}
            icon={<UploadOutlined />}
          >
            Upload property images
          </Button>
        </Upload>
        {fileList?.length <= 0 && <Empty />}
      </Spin>
    </div>
  );
}
