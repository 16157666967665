import { Button, Divider, Form, Input, message, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { get, post } from "../../../../services/userAuthAPI";
import { useDrawer } from "../../../../context/drawer";

export default function AminitiesForm() {
  const [inputData, setInputData] = useState("");
  const { drawerData, toggleDrawer } = useDrawer();

  const [additionFeature, setAdditionFeature] = useState([]);
  const [aminties, setAminties] = useState([]);
  const [furniture, setFurniture] = useState([]);
  const [searchData, setSearchData] = useState({ search: "", type: "" });

  const [selectedAminties, setSelectedAminties] = useState([]);
  const [selectedAdditionFeature, setSelectedAdditionFeature] = useState([]);
  const [selctedFurniture, setSelctedFurniture] = useState([]);


  const fetchAminites = async (type, search) => {
    try {
      if (searchData?.type !== "") {
        const resp = await get(
          `/property.php?action=fetchListAminites&limit=5&page=1&aminitie_type=${type}${
            search !== "" ? "&aminitie_name=" + search : ""
          }`
        );

        if (type === "furniture") {
          setFurniture(resp.list);
        }

        if (type === "amenities") {
          setAminties(resp.list);
        }

        if (type === "additional_feature") {
          setAdditionFeature(resp.list);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const dbounceTime = setTimeout(() => {
      fetchAminites(searchData.type, searchData.search);
    }, 300);

    return () => clearTimeout(dbounceTime);
    // eslint-disable-next-line
  }, [searchData]);

  useEffect(() => {
    const amenities = drawerData.value.aminities.map(
      (value) => value.aminitie_name+"-"+value.aminitie_id
    );
    const furnitures = drawerData.value.furniture.map(
      (value) => value.aminitie_name+"-"+value.aminitie_id
    );
    const additionalFeatures = drawerData.value.additional_feature.map(
      (value) => value.aminitie_name+"-"+value.aminitie_id
    );

    setSelectedAminties(amenities);
    setSelctedFurniture(furnitures);
    setSelectedAdditionFeature(additionalFeatures);
  }, [drawerData]);

  const handleAddnewData = async (type) => {
    try {
      const formData = new FormData();
      formData.append("aminitie_name", inputData);
      formData.append("aminitie_type", type);
      formData.append("action", "createAminities");

      const resp = await post("/property.php", formData);

      if (resp) {
        const pushData = {
          aminitie_id: resp.id,
          aminitie_name: inputData,
          aminitie_type: type,
        };

        switch (type) {
          case "furniture":
            setFurniture((prev) => [...prev, pushData]);
            break;
          case "amenities":
            setAminties((prev) => [...prev, pushData]);
            break;
          case "additional_feature":
            setAdditionFeature((prev) => [...prev, pushData]);
            break;
          default:
            break;
        }

        setInputData("");
      }
    } catch (error) {
      message.error("Sorry, unable to add. Please try again.");
      console.error(error);
    }
  };

  const handleUpdateAminity = async (type) => {
    try {
      const formData = new FormData();
      formData.append("property_id", drawerData.value.property_id);
      formData.append("action", "addAminities");

      if (selectedAminties.length > 0) {
        selectedAminties.forEach((element) => {
          formData.append("amenities[]", element.split("-")[1]);
        });
      }

      if (selctedFurniture.length > 0) {
        selctedFurniture.forEach((element) => {
          formData.append("amenities[]", element.split("-")[1]);
        });
      }

      if (selectedAdditionFeature.length > 0) {
        selectedAdditionFeature.forEach((element) => {
          formData.append("amenities[]", element.split("-")[1]);
        });
      }


      const resp = await post("/property.php", formData);

      if (resp) {
        toggleDrawer();
        message.success("Aminities updated successfully");
      }
    } catch (error) {
      message.error("Sorry, unable to add. Please try again.");
      console.error(error);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Select Aminities">
        <Select
          placeholder="Aminities"
          mode="multiple"
          value={selectedAminties}
          onChange={setSelectedAminties}
          onClick={() => setSearchData({ type: "amenities", search: "" })}
          onSearch={(value) =>
            setSearchData({ type: "amenities", search: value })
          }
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  onChange={(e) => setInputData(e.target.value)}
                  placeholder="Please enter here.."
                  value={inputData}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  onClick={() => handleAddnewData("amenities")}
                  className="otherButtons"
                  type="text"
                  icon={<FaPlus />}
                >
                  Add new aminitie
                </Button>
              </Space>
            </>
          )}
          options={aminties.map((item) => ({
            label: item.aminitie_name,
            value: item.aminitie_name + "-" + item.aminitie_id,
          }))}
        />
      </Form.Item>
      <Form.Item label="Select Furniture">
        <Select
          placeholder="Furniture"
          mode="multiple"
          value={selctedFurniture}
          onChange={setSelctedFurniture}
          onClick={() => setSearchData({ type: "furniture", search: "" })}
          onSearch={(value) =>
            setSearchData({ type: "furniture", search: value })
          }
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  onChange={(e) => setInputData(e.target.value)}
                  placeholder="Please enter here.."
                  value={inputData}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  onClick={() => handleAddnewData("furniture")}
                  className="otherButtons"
                  type="text"
                  icon={<FaPlus />}
                >
                  Add new furniture
                </Button>
              </Space>
            </>
          )}
          options={furniture.map((item) => ({
            label: item.aminitie_name,
            value: item.aminitie_name + "-" + item.aminitie_id,
          }))}
        />
      </Form.Item>
      <Form.Item label="Select Addition Feature">
        <Select
          placeholder="Addition Feature"
          mode="multiple"
          value={selectedAdditionFeature}
          onChange={setSelectedAdditionFeature}
          onClick={() =>
            setSearchData({ type: "additional_feature", search: "" })
          }
          onSearch={(value) =>
            setSearchData({ type: "additional_feature", search: value })
          }
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  onChange={(e) => setInputData(e.target.value)}
                  placeholder="Please enter here.."
                  value={inputData}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  onClick={() => handleAddnewData("additional_feature")}
                  className="otherButtons"
                  type="text"
                  icon={<FaPlus />}
                >
                  Add new addition feature
                </Button>
              </Space>
            </>
          )}
          options={additionFeature.map((item) => ({
            label: item.aminitie_name,
            value: item.aminitie_name + "-" + item.aminitie_id,
          }))}
        />
      </Form.Item>

      <Form.Item>
        <Button className="otherButtons" onClick={handleUpdateAminity}>Update Aminities</Button>
      </Form.Item>
    </Form>
  );
}
