import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Button, Divider, Tooltip } from "antd";
import Title from "antd/es/typography/Title";
export default function TitleComp({
  name,
  type,
  totalData,
  handleDrawerAdd,
  isShowButton,
}) {
  return (
    <div>
      <div className="pageHeader">
        <Title level={2}>{name}</Title>
        {isShowButton ? (
          <Tooltip title={"Add New " + type} placement="bottom">
            <Button
              style={{ display: "flex", alignItems: "center" }}
              icon={<AiOutlineCloudUpload />}
              size={55}
              onClick={handleDrawerAdd}
            >
              Add {type}
            </Button>
          </Tooltip>
        ) : null}
      </div>
      <Divider orientation="center">
        Total {type} ({totalData})
      </Divider>
    </div>
  );
}
