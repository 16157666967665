import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Empty, Upload } from "antd";

export default function ImagesForm({ fileList, setFileList }) {
  return (
    <div className="mb-4">
      <Upload
        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
        listType="picture"
        defaultFileList={[...fileList]}
        onChange={({file, fileList})=>setFileList(fileList)}
        multiple={true}
        accept="image/*"
      >
        <Button
          style={{ width: "100%", border: "1px solid #000", height: "40px" }}
          icon={<UploadOutlined />}
        >
          Upload property images
        </Button>
      </Upload>
      {fileList?.length <= 0 && <Empty />}
    </div>
  );
}
