import React, { useState } from "react";
import { Button, Pagination, Select, Table } from "antd";
import Search from "antd/es/input/Search";
import { HiRefresh } from "react-icons/hi";

const TableComp = ({
  data,
  columns,
  loading,
  totalData,
  limitComp,
  pageComp,
  setLimitComp,
  setPageComp,
  fetchData,
  isShowFilter
}) => {
  const [category, setCategory] = useState("all");
  const [type, setType] = useState("all");
  const handleChange = (page, pageSize) => {
    setPageComp(page);
    setLimitComp(pageSize);
  };

  const handleSubmit = (value, event) => {
    fetchData(pageComp, limitComp, value, category, type);
  };

  return (
    <>
      <div
        className="headerTitle"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="d-flex align-items-start justify-content-start">
          <Search
            style={{ marginBottom: "30px", width: "350px" }}
            placeholder="Search here.."
            loading={loading}
            enterButton
            onSearch={handleSubmit}
          />
          {isShowFilter && (
            <Select
              placeholder="Select Category"
              value={category}
              style={{ width: "200px", margin: "0 10px" }}
              onChange={(value) => {
                fetchData(pageComp, limitComp, "", value, type);
                setCategory(value);
              }}
              options={[
                {
                  label: "All",
                  value: "all",
                },
                {
                  label: "Residential",
                  value: "residential",
                },
                {
                  label: "Commercial",
                  value: "commercial",
                },
              ]}
            />
          )}

          {isShowFilter && (
            <Select
              placeholder="Select Type"
              value={type}
              style={{ width: "200px" }}
              onChange={(value) => {
                fetchData(pageComp, limitComp, "", category, value);
                setType(value);
              }}
              options={[
                {
                  label: "All",
                  value: "all",
                },
                {
                  label: "Outright",
                  value: "outright",
                },
                {
                  label: "Lease",
                  value: "lease",
                },
              ]}
            />
          )}
        </div>
        <Button
          type="primary"
          loading={loading}
          icon={<HiRefresh />}
          style={{ marginBottom: "30px" }}
          onClick={() => fetchData(pageComp, limitComp, "", "", "")}
        >
          Refresh Data
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          position: ["none", "none"],
        }}
      />
      <Pagination
        style={{ marginTop: "30px" }}
        total={totalData}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        pageSize={limitComp}
        hideOnSinglePage
        current={pageComp}
        onChange={handleChange}
      />
    </>
  );
};
export default TableComp;
