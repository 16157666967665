import React, { useEffect, useState } from 'react'
import TitleComp from '../components/Title/title'
import TableComp from '../components/table/tables'
import { get } from '../services/userAuthAPI';

export default function Enquiries() {
  const columns = [
    {
      title: "Name",
      dataIndex: "enquiry_name",
      key: "enquiry_name",
    },

    {
      title: "Phone",
      dataIndex: "enquiry_phone",
      key: "enquiry_phone",
    },

    {
      title: "Email",
      dataIndex: "enquiry_email",
      key: "enquiry_email",
    },

    {
      title: "Property Name",
      dataIndex: "property_name",
      key: "property_name",
    },

    {
      title: "Message",
      dataIndex: "enquiry_message",
      key: "enquiry_message",
    },


  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);

  const fetchData = async (page, limit, search, category, type) => {
    try {
      setLoading(true);

      const resp = await get(
        `/enquiry.php?action=fetch&limit=${limit}&page=${page}${
          search !== "" ? "&search=" + search : ""
        }`
      );

      if (resp) {
        setLoading(false);
        setTotalData(resp.total);
        setFetchList(resp.list);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "", "all", "all");
  }, [limitComp, pageComp]);


  return (
    <div>
        <TitleComp
        name="Properties"
        type="Properties"
        totalData={totalData}
        isShowButton={false}
        handleDrawerAdd={null}
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        isShowFilter = {false}
        fetchData={fetchData}
      />
    </div>
  )
}
