import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SideBarContext from "./context/nav-toggle";
import DrawerContext from "./context/drawer";
import { Provider } from "react-redux";
import { store } from "./app/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SideBarContext>
        <DrawerContext>
          <App />
        </DrawerContext>
      </SideBarContext>
    </Provider>
  </React.StrictMode>
);
