import { Button, Divider, Form, Input, message, Select, Space } from "antd";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { post } from "../../../../services/userAuthAPI";

export default function AminitiesForm({
  setAminties,
  aminties,
  setAdditionFeature,
  additionFeature,
  setFurniture,
  furniture,
  selectedAminties,
  setSelectedAminties,
  selectedAdditionFeature,
  setSelectedAdditionFeature,
  selctedFurniture,
  setSelctedFurniture,
  setSearchData,
}) {
  const [inputData, setInputData] = useState("");

  const handleAddnewData = async (type) => {
    try {
      const formData = new FormData();
      formData.append("aminitie_name", inputData);
      formData.append("aminitie_type", type);
      formData.append("action", "createAminities");

      const resp = await post("/property.php", formData);

      if (resp) {
        const pushData = {
          aminitie_id: resp.id,
          aminitie_name: inputData,
          aminitie_type: type,
        };

        switch (type) {
          case "furniture":
            setFurniture((prev) => [...prev, pushData]);
            break;
          case "amenities":
            setAminties((prev) => [...prev, pushData]);
            break;
          case "additional_feature":
            setAdditionFeature((prev) => [...prev, pushData]);
            break;
          default:
            break;
        }

        setInputData("");
      }
    } catch (error) {
      message.error("Sorry, unable to add. Please try again.");
      console.error(error);
    }
  };


  return (
    <Form layout="vertical">
      <Form.Item label="Select Aminities">
        <Select
          placeholder="Aminities"
          mode="multiple"
          value={selectedAminties}
          onChange={setSelectedAminties}
          onClick={() => setSearchData({ type: "amenities", search: "" })}
          onSearch={(value) =>
            setSearchData({ type: "amenities", search: value })
          }
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  onChange={(e) => setInputData(e.target.value)}
                  placeholder="Please enter here.."
                  value={inputData}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  onClick={() => handleAddnewData("amenities")}
                  className="otherButtons"
                  type="text"
                  icon={<FaPlus />}
                >
                  Add new aminitie
                </Button>
              </Space>
            </>
          )}
          options={aminties.map((item) => ({
            label: item.aminitie_name,
            value: item.aminitie_name+"-"+item.aminitie_id,
          }))}
        />
      </Form.Item>
      <Form.Item label="Select Furniture">
        <Select
          placeholder="Furniture"
          mode="multiple"
          value={selctedFurniture}
          onChange={setSelctedFurniture}
          onClick={() => setSearchData({ type: "furniture", search: "" })}
          onSearch={(value) =>
            setSearchData({ type: "furniture", search: value })
          }
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  onChange={(e) => setInputData(e.target.value)}
                  placeholder="Please enter here.."
                  value={inputData}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  onClick={() => handleAddnewData("furniture")}
                  className="otherButtons"
                  type="text"
                  icon={<FaPlus />}
                >
                  Add new furniture
                </Button>
              </Space>
            </>
          )}
          options={furniture.map((item) => ({
            label: item.aminitie_name,
            value: item.aminitie_name+"-"+item.aminitie_id,
          }))}
        />
      </Form.Item>
      <Form.Item label="Select Addition Feature">
        <Select
          placeholder="Addition Feature"
          mode="multiple"
          value={selectedAdditionFeature}
          onChange={setSelectedAdditionFeature}
          onClick={() =>
            setSearchData({ type: "additional_feature", search: "" })
          }
          onSearch={(value) =>  
            setSearchData({ type: "additional_feature", search: value })
          }
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input
                  onChange={(e) => setInputData(e.target.value)}
                  placeholder="Please enter here.."
                  value={inputData}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  onClick={() => handleAddnewData("additional_feature")}
                  className="otherButtons"
                  type="text"
                  icon={<FaPlus />}
                >
                  Add new addition feature
                </Button>
              </Space>
            </>
          )}
          options={additionFeature.map((item) => ({
            label: item.aminitie_name,
            value: item.aminitie_name+"-"+item.aminitie_id,
          }))}
        />
      </Form.Item>
    </Form>
  );
}
