import { Button, Form, Input, message, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { PiCurrencyInrBold } from "react-icons/pi";
import { get, post } from "../../../../services/userAuthAPI";
import { useDrawer } from "../../../../context/drawer";

const residentialSpace = [
  "Any",
  "1 RK",
  "1 BHK",
  "2 BHK",
  "3 BHK",
  "4 BHK",
  "5 BHK",
  "7 BHK",
  "8 BHK",
  "9 BHK",
  "10 BHK",
  "Plots",
  "Bunglows",
  "Preleased",
];
const comercialSpace = [
  "Shop",
  "Office",
  "Showrooms",
  "Preleased",
  "Resturant",
  "Coworking Space",
];

export default function DetailFormUpdate() {
  const [selectedCategory, setSelectedCategory] = useState("residential");
  const [locationType, setLocationType] = useState("Old");

  const [locations, setLocations] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const { drawerData, toggleDrawer } = useDrawer();

  const fetchLocation = async (search) => {
    try {
      const resp = await get(
        `/property.php?action=fetchLocation${
          search !== "" ? "&locality=" + search : ""
        }`
      );

      if (resp) {
        setLocations(resp.list);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchLocation(search);
    }, 300);

    return () => clearTimeout(renderTime);
  }, [search]);

  useEffect(() => {
    form.setFieldsValue({
      ...drawerData.value,
      price: formatNumber(drawerData.value.price).split(" ")[0],
      type: formatNumber(drawerData.value.price).split(" ")[1],
    });
  }, [drawerData]);

  const handleUpdate = async (value) => {
    try {
      setLoading(true);
      const formsData = new FormData();

      for (const [key, val] of Object.entries(value)) {
        if (key === "price") {
          formsData.append("price", convertToInteger(value.price + " " + value.type));
        } else {
          formsData.append(key, val);
        }
      }

      formsData.append("action", "updateProperty");

      const resp = await post("/property.php", formsData);

      if (resp) {
        setLoading(false);
        message.success("Property Uploded successfully");
        toggleDrawer();
      }
    } catch (error) {
      console.log(error);
      message.error("Unable to update please try again");
    }
  };

  function convertToInteger(amountStr) {
    // Trim and convert the input string to lowercase
    const normalizedStr = amountStr.trim().toLowerCase();

    // Define conversion rates
    const lakhMultiplier = 100000;
    const thousandMultiplier = 1000;
    const croreMultiplier = 10000000;

    // Match and convert based on suffix
    if (normalizedStr.endsWith("cr")) {
      return (
        parseFloat(normalizedStr.replace("cr", "").trim()) * croreMultiplier
      );
    } else if (normalizedStr.endsWith("lac")) {
      return (
        parseFloat(normalizedStr.replace("lac", "").trim()) * lakhMultiplier
      );
    } else if (normalizedStr.endsWith("k")) {
      return (
        parseFloat(normalizedStr.replace("k", "").trim()) * thousandMultiplier
      );
    } else {
      // Default case: assume it’s a plain number
      return parseFloat(normalizedStr);
    }
  }

  function formatNumber(num) {
    if (num >= 1000 && num < 10000) {
      return (num / 1000).toFixed(1) + " k";
    } else if (num >= 100000 && num < 10000000) {
      return (num / 100000).toFixed(1) + " lac";
    } else if (num >= 10000000 && num <= 1000000000) {
      return (num / 10000000).toFixed(1) + " cr";
    } else {
      return num.toString(); // For numbers outside the specified ranges
    }
  }

  return (
    <div>
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={handleUpdate}
      >
        <div className="row">
          <div className="col-md-12 d-none">
            <Form.Item label="Name" name="property_id">
              <Input className="formInput" />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Form.Item
              label="Name"
              name="property_name"
              rules={[{ required: true }]}
            >
              <Input className="formInput" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Category"
              name="property_categ"
              rules={[{ required: true }]}
            >
              <Select
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                placeholder="Select Category"
                value={selectedCategory}
                onChange={(value) => setSelectedCategory(value)}
                options={[
                  {
                    label: "Residential",
                    value: "residential",
                  },
                  {
                    label: "Commercial",
                    value: "commercial",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Type"
              name="property_type"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select Category"
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                options={[
                  {
                    label: "Outright",
                    value: "outright",
                  },
                  {
                    label: "Lease",
                    value: "lease",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Space"
              name="property_space"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select Space"
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
              >
                {(selectedCategory === "residential"
                  ? residentialSpace
                  : comercialSpace
                )?.map((valueData) => (
                  <Select.Option value={valueData} label={valueData}>
                    {valueData}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-3">
            <Form.Item label="Price" name="price" rules={[{ required: true }]}>
              <Input
                style={{ width: "100%" }}
                className="formInput"
                prefix={<PiCurrencyInrBold />}
                min={0}
              />
            </Form.Item>
          </div>
          <div className="col-md-3">
            <Form.Item label=" " name="type">
              <Select
                placeholder="Amt Type"
                options={[
                  { label: "K", value: "K" },
                  { label: "Lac", value: "Lac" },
                  { label: "Cr", value: "Cr" },
                ]}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Usable Carpet Area"
              name="usable_carpet"
              rules={[{ required: true }]}
            >
              <Input className="formInput" suffix="Sq.Ft" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Rera Carpet Area"
              name="rera_carpet"
              rules={[{ required: true }]}
            >
              <Input className="formInput" suffix="Sq.Ft" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Possession"
              name="possession"
              rules={[{ required: true }]}
            >
              <Input className="formInput" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Age of Property"
              name="age_of_property"
              rules={[{ required: true }]}
            >
              <Input className="formInput" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Condition"
              name="property_condition"
              rules={[{ required: true }]}
            >
              <Input className="formInput" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="configuration"
              name="configuration"
              rules={[{ required: true }]}
            >
              <Input className="formInput" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Floor" name="floor" rules={[{ required: true }]}>
              <Input className="formInput" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Bathroom"
              name="bathroom"
              rules={[{ required: true }]}
            >
              <Input className="formInput" />
            </Form.Item>
          </div>

          <Form.Item
            label="Parking"
            name="parking"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Parking"
              style={{ minHeight: "35px", borderColor: "#afafaf" }}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
          </Form.Item>
          <div className="col-md-4">
            <Form.Item label="Location Type" name="location_type">
              <Select
                value={locationType}
                onChange={(value) => setLocationType(value)}
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                onSearch={(value) => setSearch(value)}
                placeholder="Type"
                options={[
                  { label: "Previous Location", value: "Old" },
                  { label: "Add New Location", value: "New" },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-md-8">
            <Form.Item
              label="Select Location"
              name="locality"
              rules={[{ required: true }]}
            >
              {locationType === "Old" ? (
                <Select
                  placeholder="Select Location"
                  style={{ minHeight: "35px", borderColor: "#afafaf" }}
                >
                  {locations?.map((value) => (
                    <Select.Option
                      value={value.locality}
                      label={value.locality}
                    >
                      {value.locality}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input className="formInput" />
              )}
            </Form.Item>
          </div>

          <Form.Item
            label="Address"
            name="property_address"
            rules={[{ required: true }]}
          >
            <TextArea showCount maxLength={500} />
          </Form.Item>

          <Form.Item
            label="Description"
            name="property_desc"
            rules={[{ required: true }]}
          >
            <TextArea showCount maxLength={500} />
          </Form.Item>

          <div className="col-12">
            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                className="otherButtons"
                style={{ height: "40px" }}
              >
                Update Detail
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
